<template>
    <div class="currency">
        <solution-select class="solutionWrap" v-if="showSolution" @close='close' ></solution-select>
        <img class="bannerImg" src="../assets/images/currency.png" alt="">
        <div class="panorama">
            <p class="panoramaTitle">{{pages.name}}解决方案</p>
            <img style="margin-top: 0.5rem;" class="bannerImg" src="../assets/images/solution/banner.png" alt="">
              <div class="banner">
                <p class="title">人工智能+在线教育：</p>
                <p class="reform">我们是教育行业的变革者</p>
                <p class="tip">Be The Best Adaptive Learning Platform</p>
            </div>
        </div>
        <p class="panoramaTitle">方案简介</p>
        <div class="page_wrap" style="margin:0.5rem auto;">
            <div class="page_content" style="maxWidth:15rem">
                <div v-for="(item, index) in pages.photoList" :key="index">
                    <div class="newsList-item news-flex">
                        <div class="newsList-item-left background" :style="{ 'backgroundImage': `url(${item.src})`}">
                        </div>
                        <div class="newsList-item-right">
                            <div class="newsItem-content">
                                {{ item.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="currencyAdvantage">
            <p class="title">方案优势</p>
            <div class="page_flex" style="width:100%">
                <div class="left">
                    <p class="titleChildren">专业团队为您打造专业方案</p>
                    <div class="text">{{pages.advantage.left}}</div>
                </div>
                <div class="right">
                    <p class="titleChildren">优异的资源满足您的需求</p>
                    <div class="text">{{pages.advantage.right}}</div>
                </div>
            </div>
        </div>
<!--        <div class="customerCase">-->
<!--            <p class="panoramaTitle">客户案例</p>-->
<!--            <div class="page_wrap" style="margin:0.5rem auto;">-->
<!--                <div class="page_content" style="maxWidth:15rem">-->
<!--                    <div v-for="(item, index) in pages.caseList" :key="index">-->
<!--                        <div class="newsList-item news-flex">-->
<!--                            <div class="newsList-item-left background" :style="{ 'backgroundImage': `url(${item.src})`}">-->
<!--                            </div>-->
<!--                            <div class="newsList-item-right">-->
<!--                                <div class="newsItem-title news-flex">-->
<!--                                    <span>{{ item.name }}</span>-->
<!--                                </div>-->
<!--                                <div class="newsItem-content">-->
<!--                                    {{ item.content }}<br>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="partner">
            <p class="panoramaTitle">合作伙伴</p>
            <p class="partnerTitle">我们将与众多同行者一起共建人工智能产业生态</p>
            <div class="partnerList">
                <img v-for="(item, index) in imglist" :key="index"  :src="item" style="margin: 0 0.5rem;">
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import solutionSelect from '../../src/views/solutionSelect.vue'
export default {
    name: 'solution',
    components:{solutionSelect},
    watch: {
        '$store.state.pages'(val) {
            this.pages = val
        },
        $route:{
            deep:true,
            immediate:true,
            handler(nv,ov){
                this.pages=JSON.parse(localStorage.getItem('data')).pages
            }
        }
    },
    data () {
        return {
            pages: {
                name: '智算云平台',
                photoList: [{name: '智算云平台',
                    src: require('@/assets/images/industry2.png'),
                    content: '南京智能计算中心由南京市麒麟科技创新园携手浪潮、寒武纪共同打造，采用领先的寒武纪思元270和思元290智能芯片及加速卡。通过算力的生产、聚合、调度和释放四大关键作业环节，提供人工智能应用所需的强大算力服务。已投入运营的AI算力规模，1小时可完成100亿张图像识别、300万小时语音翻译或1万公里的自动驾驶AI数据处理任务。',
                }],
                caseList: [{name: '工业视觉—智能矿石检测',
                    src: require('@/assets/images/solution/case.png'),
                    content: '传统的生产流水线上产品下线后大多有一个目检环节，即通过人眼检查发现产品表面的缺陷和瑕疵，人工目检效率低、容易漏检，并且人工成本也越来越高，智能云边一体开放平台通过视觉分析算法模型极大提高了检测效率。'
                }],
                advantage: {
                    left: '完全由南京麒麟管委会投资建设，全系列使用浪潮智能计算优化服务器及国产AI 计算卡，具有大规模、自主可控的优势。是“新基建”的重要组成部分，也是为全社会提供算力的重要公共服务设施。',
                    right: '机房已通过等保三级认证，能够在统一安全策略下防护系统免受来自外部有组织的团体、拥有较为丰富资源的威胁源发起的恶意攻击、较为严重的自然灾难、以及其他相当危害程度的威胁所造成的主要资源损害，能够发现安全漏洞和安全事件，在系统遭到损害后，能够较快恢复绝大部分功能。'
                },
            },
            showSolution: true,
            imglist:[
                require('@/assets/images/partner/1.png'),require('@/assets/images/partner/2.png'),require('@/assets/images/partner/3.png'),
                require('@/assets/images/partner/4.png'),require('@/assets/images/partner/5.png'),require('@/assets/images/partner/6.png'),
                require('@/assets/images/partner/7.png'),require('@/assets/images/partner/8.png'),require('@/assets/images/partner/9.png'),
                require('@/assets/images/partner/10.png'),require('@/assets/images/partner/11.png'),require('@/assets/images/partner/12.png'),
                require('@/assets/images/partner/13.png'),require('@/assets/images/partner/14.png'),require('@/assets/images/partner/15.png'),
                require('@/assets/images/partner/16.png'),require('@/assets/images/partner/17.png'),require('@/assets/images/partner/18.png'),
                require('@/assets/images/partner/19.png'),require('@/assets/images/partner/20.png'),require('@/assets/images/partner/21.png'),
            ],
        }
    },
    created () {
        document.body.scrollTop = document.documentElement.scrollTop = 0
        this.pages = JSON.parse(localStorage.getItem('data')).pages
        console.log(this.pages)
    },
    beforeDestroy(){
       localStorage.getItem('data')
    },
    methods: {
        toRoute (val) {
            this.$router.push(val)
        },
        close(){
            this.showSolution = false
        },
        getData(data) {
            this.pages = data.pages
        }
    }
}
</script>
<style lang="scss" scoped>
.panorama{
    position: relative;
}
.banner{
    top:65%;
    // right:20%;
    .title,.reform{
        font-size: 0.4rem;
        font-weight: 600;
        margin-top: 0.2rem;
        font-style: italic;
    }
    p:nth-child(2){
        line-height: 0.5rem;
    }
    .tip{
      font-size: 0.3rem;
      margin-top: 0.5rem;
    }

}
.currencyAdvantage {
    width: 100%;
    height: 3.6rem;
    background: #F7F7F7;
    .title {
        color: #333333;
        font-size: 28px;
        padding: 0.2rem 0;
    }
    .titleChildren {
        color: #333333;
        font-size: 18px;
        padding: 0.2rem 0;
        font-weight: 600;
    }
    .left,.right {
        display: inline-block;
        width: 38%;
        height: 2rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.03rem 0.1rem 0rem rgba(204, 204, 204, 0.46);
        border-radius: 0.03rem;
        margin: 0.2rem auto;
        .text {
            padding: 0.2rem 1rem;
            text-align: left;
            color: #666666;
        }
    }
    .right {
        margin-left: 4%;
    }
}
.background-bottom{
    position:absolute;
    width: 100%;
    bottom:0;
    left:0;
    background: #ffffff7c;
    height:0.5rem;
}
.background-bottom-content{
    width: 70%;
    height: 0.5rem;
    line-height: 0.5rem;
    display: flex;
    justify-content: space-between;
    margin:0 auto;
    &-item{
        width: 0.8rem;
        height: 0.5rem;
        color:#606266;
        cursor: pointer;
        font-size: 0.14rem;
    }
    &-item:hover{
        color:#0061E3;
        border-bottom:1px solid #0061E3;
    }
}
.currency {
    @keyframes partnerAnima
    {
        0%   {right: 0}
        50%  {right: 30rem}
        100%  {right: 60rem}
    }
    .partnerList {
        position: relative;
        animation: partnerAnima 100s linear 1 alternate;
    }
    width: 100%;
    height: 100%;
    text-align: center;
    .detail {
        background: #F7F7F7;
        width: 80%;
        margin: 0.5rem auto;
        padding: 0.2rem;
        .text {
            text-align: left;
            width: 60%;
            margin: 0.2rem auto;
            color: #333333;
            font-size: 0.14rem;
        }
    }
    .partner {
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
        .partnerList {
            height: 1.2rem;
            width: 61rem;
            img {
                height: 1.2rem;
                float: left;
            }
        }
        .partnerTitle {
            color: #999999;
            font-size: 0.16rem;
            margin: 0.2rem 0;
        }
    }
    .panoramaTitle {
        text-align: center;
        font-size: 0.28rem;
        margin-top: 0.6rem;
        color: #333;
        border-radius: 0.2rem;
        display: inline-block;
        padding: 0.1rem 0.2rem;
    }
    .panorama {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        .productTab {
            width:100%;
            height: 4.5rem;
            margin: 0.5rem auto auto auto;
            .productChildren {
                //margin-bottom: 0.4rem;
                height: 3rem;
                .left, .right {
                    display: inline-block;
                    width: 20%;
                    height: 100%;
                    background-color: #F7F7F7;
                    border-top: 2px solid #0061E3;
                    text-align: left;
                    position: relative;
                    box-shadow: 0px 3px 8px 0px rgba(187, 187, 187, 0.3);
                    .text {
                        display: inline-block;
                        width: 90%;
                        height: 100%;
                        overflow: hidden;
                        padding: 0.2rem;
                        div {
                            padding: 0.2rem;
                            font-size: 0.16rem;
                            span {
                                padding: 0.1rem;
                                cursor: pointer;
                            }
                            .active {
                                border-bottom: 1px solid #0061E3;
                                color: #0061E3;
                            }
                        }
                    }
                }
                .right {
                    margin-left: 5%;
                }
            }
            .application {
                height: 4rem;
                overflow: hidden;
            }
            .tabs {
                margin-bottom: 0.4rem;
                span {
                    display: inline-block;
                    width: 20%;
                    height: 0.64rem;
                    border-radius: 0.03rem;
                    background: #F7F7F7;
                    cursor: pointer;
                    color: #0061E3;
                    line-height: 0.6rem;
                    font-size: 0.24rem;
                }
                .active {
                    background: #0061E3;
                    color: #FFFFFF;
                }
            }
        }
    }
}
.customerCase {
    .newsList-item {
        &-left{
            width:4.4rem;
            height: 2.8rem;
        }
        &-right {
            width: calc(100% - 4.8rem);
        }
    }
}
.news-flex{
    display: flex;
    justify-content: space-between;
}
.newsList-item{
    height: 3rem;
    padding:0.2rem 0.1rem;
    border-top:1px solid #dedede;
    border-bottom:1px solid #dedede;
    text-align: left;
    &-left{
        width:7.4rem;
        height: 2.8rem;
    }
    &-right{
        width: calc(100% - 7.8rem);
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        .newsItem-title{
            text-align: left;
            font-size: 0.22rem;
            color:#333;
        }
    }
    .newsItem-content{
        font-size: 0.13rem;
        color:#606266;
        line-height: 0.26rem;
        height:1.5rem;
        width:100%;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient:vertical;
        /* autoprefixer: on */
        -webkit-line-clamp:6;
    }
    .newsItem-link{
        width: 100%;
        height:0.4rem;
        &-button{
            width: 0.3rem;
            height:0.3rem;
            float: right;
        }
    }
}
.newsList-item:hover {
    background: #f0f6ff;
    cursor: pointer;
}
</style>